/* 
This hook does too much, and should be split up further by functionality.
However, there are too many circular dependencies to do so without a major refactor.
This will be addressed and fixed in https://linear.app/clipboardhealth/project/requirements-uiux-improvements-58d4fffc7bd4
Therefore, we are temporarily disabling the import/max-dependencies lint rule.
*/
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import constate from "constate";
import { type Location } from "history";
import { useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  ACCOUNTS_BASE_PATH,
  ACCOUNTS_DOCUMENTS_PATH,
  DocumentRequirementType,
  useGetRequirementStatus,
} from "../../Documents";
import { ACCOUNT_ROUTE_V1 } from "../../Documents/constants";
import { getRequirementType } from "../../utils/getRequirementType";
import { PERSONAL_IDENTIFICATION_REQUIREMENT_ID } from "../constants";
import { useHelloSignEmbeddedFlow } from "../hooks/hellosign/useHelloSignEmbeddedFlow";
import { useDocumentLoadingModals } from "../hooks/useDocumentLoadingModals";
import { useDocumentPreview } from "../hooks/useDocumentPreview";
import { useDocumentProperties } from "../hooks/useDocumentProperties";
import { useGetInstructionsAndFallbackUrl } from "../hooks/useGetInstructionsAndFallbackUrl";
import { useLogDocumentDetailsEvent } from "../hooks/useLogDocumentDetailsEvent";
import { useStripeVerificationFlow } from "../hooks/useStripeVerificationFlow";
import { useDocumentDetailsUrlParams } from "../hooks/useUrlParams";
import { getRequirementDetailsStatus } from "../utils";

interface LocationState {
  documentsDropOffFlowId: string;
  returnLocation?: Location;
}

function useDocumentDetails() {
  const { state: locationState } = useLocation<LocationState>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qualificationFromSearchParams = searchParams.get("qualification") ?? undefined;

  const {
    requirementId,
    requirementType: requirementTypeFromUrlParams,
    facilityId,
  } = useDocumentDetailsUrlParams();

  const documentsDropOffFlowId = useRef<string>(locationState?.documentsDropOffFlowId ?? uuidv4());
  const documentDetailsPageContentRef = useRef<HTMLDivElement>(null);
  const [helloSignClientIsOpen, setHelloSignClientIsOpen] = useState(false);

  const worker = useDefinedWorker();

  const {
    hcpRequirementStatus,
    refetch: refetchRequirementStatus,
    isLoading: isLoadingRequirementStatus,
  } = useGetRequirementStatus({
    workerId: worker.userId,
  });

  const requirementType =
    requirementTypeFromUrlParams ??
    getRequirementType({
      requirementId,
      hcpRequirementStatus,
    });

  const {
    documentProperties,
    isLoading: isLoadingDocumentProperties,
    refetchDocumentProperties,
  } = useDocumentProperties({
    requirementId,
    requirementType,
    facilityId,
    qualification: qualificationFromSearchParams,
  });

  const {
    selectedRequirement,
    documentId,
    submittedDocumentSubtype,
    rejectedDocumentNotification,
    fileStorageFileKey,
    fileStorageRootFolder,
  } = documentProperties;

  useLogEffect(
    APP_V2_USER_EVENTS.DOCUMENTS_UPLOAD_FLOW_DOCUMENTS_DETAILS_PAGE_VIEWED,
    {
      documentsDropOffFlowId: documentsDropOffFlowId.current,
      requirementId,
      requirementStatus: requirementTypeFromUrlParams,
      workplaceId: facilityId,
      requirementWorkflowType: selectedRequirement?.requirementWorkflowType,
    },
    { enabled: isDefined(selectedRequirement) }
  );

  useLogDocumentDetailsEvent({
    selectedRequirement,
    documentRequirementType: requirementType,
  });

  const history = useHistory();

  const [personalIdSubtype, setPersonalIdSubtype] = useState<string>();
  const deleteDocumentModalState = useModalState();

  const isRequirementPersonalId = requirementId === PERSONAL_IDENTIFICATION_REQUIREMENT_ID;

  const {
    personalIdSubtypes,
    stripePersonalIdSubtypes,
    startStripeVerificationFlow,
    isStripeReady,
    isLoadingStripeVerificationSession,
    isStripeVerificationFlow,
  } = useStripeVerificationFlow({
    isRequirementPersonalId,
    personalIdSubtype,
  });

  if (isRequirementPersonalId && personalIdSubtypes.length > 0 && !isDefined(personalIdSubtype)) {
    setPersonalIdSubtype(personalIdSubtypes[0]);
  }

  const alertRequirementStatus = getRequirementDetailsStatus({
    selectedRequirement,
    documentRequirementType: requirementType,
  });

  const backLink =
    locationState?.returnLocation?.pathname ?? `${ACCOUNTS_BASE_PATH}/${ACCOUNTS_DOCUMENTS_PATH}`;
  const refreshQueryAndGoBackToDocumentsPage = async () => {
    if (isDefined(selectedRequirement)) {
      await refetchRequirementStatus();
    }

    history.replace(backLink, {
      ...(backLink === `${ACCOUNTS_BASE_PATH}/${ACCOUNTS_DOCUMENTS_PATH}`
        ? { returnUrl: ACCOUNT_ROUTE_V1 }
        : {}),
    });
  };

  const requirementTypeIsPending =
    isDefined(requirementType) && requirementType === DocumentRequirementType.PENDING;

  const { onClick: viewDocumentOnClick, isLoading: isDocumentPreviewLoading } =
    useDocumentPreview();

  const areFileKeysMissing = !isDefined(fileStorageFileKey) || !isDefined(fileStorageRootFolder);

  // Hide view document button if uploaded doc is through stripe, and is
  // still pending since document may not be available to view yet
  const viewDocumentButtonIsVisible =
    !areFileKeysMissing &&
    (!isRequirementPersonalId ||
      !isDefined(submittedDocumentSubtype) ||
      !stripePersonalIdSubtypes.includes(submittedDocumentSubtype) ||
      !requirementTypeIsPending);

  const documentUploadingModalStatesAndSetters = useDocumentLoadingModals();

  const { startHelloSignEmbeddedFlow, isLoadingHelloSignDocument, isCreatingHelloSignSession } =
    useHelloSignEmbeddedFlow({
      selectedRequirement,
      documentsDropOffFlowId: documentsDropOffFlowId.current,
      refetchDocumentProperties,
      documentDetailsPageContentRef,
      setHelloSignClientIsOpen,
      ...documentUploadingModalStatesAndSetters,
    });

  const { fallbackStaticUrl, instructions } = useGetInstructionsAndFallbackUrl({
    selectedRequirement,
  });

  return {
    requirementId,
    requirementType,
    selectedRequirement,
    isLoadingDocumentProperties: isLoadingDocumentProperties || isLoadingRequirementStatus,
    documentId,
    rejectedDocumentNotification,
    isRequirementPersonalId,
    personalIdSubtype,
    setPersonalIdSubtype,
    personalIdSubtypes,
    startStripeVerificationFlow,
    isStripeReady,
    isLoadingStripeVerificationSession,
    isStripeVerificationFlow,
    backLink,
    deleteDocumentModalState,
    refreshQueryAndGoBackToDocumentsPage,
    alertRequirementStatus,
    requirementTypeIsPending,
    isDocumentPreviewLoading,
    viewDocumentOnClick,
    viewDocumentButtonIsVisible,
    startHelloSignEmbeddedFlow,
    isLoadingHelloSignDocument,
    isCreatingHelloSignSession,
    refetchDocumentProperties,
    documentDetailsPageContentRef,
    helloSignClientIsOpen,
    ...documentUploadingModalStatesAndSetters,
    fallbackStaticUrl,
    instructions,
    documentsDropOffFlowId: documentsDropOffFlowId.current,
    selectedQualification: qualificationFromSearchParams,
  };
}

export const [DocumentDetailsProvider, useDocumentDetailsContext] = constate(useDocumentDetails);
